import Btn from '@/components/Btn/Btn.vue';
import CATEGORIES_QUERY from '@/graphql/queries/categories.gql';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';

export default {
  name: 'SystemForm',
  components: { Btn, NumberTextField },
  props: {
    btnLabel: { type: String, required: true },
    loading: { type: Boolean, default: false },
    system: { type: Object },
  },
  apollo: {
    categories: {
      query: CATEGORIES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load categories.' }
          : error;
      },
    },
  },
  data: () => ({
    ...validateRules,
    nameRules: [v => !!v || 'Name is Required'],
    categoryRules: [v => !!v || 'Category is Required'],
    categories: [],
    error: null,
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit', this.user);
      }
    },
  },
};
