import Btn from '@/components/Btn/Btn.vue';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import UNITS_QUERY from '@/graphql/queries/units.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import validateRules from '@/services/validateRules';
import { sortByName } from '@/utils/sortByName';

export default {
  name: 'ComponentForm',
  components: { Btn },
  apollo: {
    units: {
      query: UNITS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load unit list.' }
          : error;
      },
    },
    systems: {
      query: SYSTEMS_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load system list.' }
          : error;
      },
      update(data) {
        return data.systems.sort(sortByName);
      },
    },
  },
  props: {
    btnLabel: { type: String, required: true },
    loading: { type: Boolean, default: false },
    component: { type: Object },
  },
  data: () => ({
    nameRules: [v => !!v || 'Name is Required'],
    costRules: [
      v => (v !== null && v !== '') || 'Cost is Required',
      validateRules.positiveNumber,
      validateRules.floatNumber(2),
    ],
    unitRules: [v => !!v || 'Unit is Required'],
    units: [],
    systemRules: [v => !!v || 'System is Required'],
    systems: [],
    error: null,
  }),
  computed: {
    displayedRoles() {
      const canAddAdminUser = accessByPermissions(PERMISSIONS.ADD_ADMIN, this.currentUser);
      return canAddAdminUser ? this.roles : this.roles.filter(r => r.name !== 'admin');
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit', this.user);
      }
    },
  },
};
