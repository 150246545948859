import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import ManageDatabase from '@/components/ManageDatabase/ManageDatabase.vue';
import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import AddSystem from '@/components/ManageDatabase/AddSystem/AddSystem.vue';
import AddComponent from '@/components/ManageDatabase/AddComponent/AddComponent.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
export default {
  components: { AddSystem, AddComponent, Modal, ManageDatabase, Btn },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
  }),
  computed: {
    showAddSystemBtn() {
      return accessByPermissions(PERMISSIONS.CREATE_SYSTEM, this.currentUser);
    },
    showAddComponentBtn() {
      return accessByPermissions(PERMISSIONS.CREATE_DATABASE_COMPONENT, this.currentUser);
    },
  },
};
