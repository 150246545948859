import ComponentForm from '@/components/ManageDatabase/ComponentForm/ComponentForm.vue';
import ADD_COMPONENT_MUTATION from '@/graphql/mutations/addComponent.gql';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'AddComponent',
  components: { ComponentForm },
  data: () => ({
    ADD_COMPONENT_MUTATION,
    component: { system: null, name: '', costPerUnit: 1, unit: null },
  }),
  methods: {
    updateSystems(store, { data: { addComponent } }) {
      if (addComponent) {
        // Read the data from our cache for this query.
        const data = store.readQuery({
          query: SYSTEMS_QUERY,
        });
        const index = data.systems.findIndex(s => s.id === addComponent.system.id);
        if (index !== -1) {
          if (data.systems[index].components === null) {
            data.systems[index].components = [];
          }
          data.systems[index].components.unshift(addComponent);
          store.writeQuery({
            query: SYSTEMS_QUERY,
            data,
          });
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Component ${this.component.name} was added successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
