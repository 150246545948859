var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-database"},[_c('v-row',{staticClass:"mb-6"},[_c('v-col',[_c('h1',[_vm._v("Manage Database")])]),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAddSystemBtn),expression:"showAddSystemBtn"}],attrs:{"cols":"auto"}},[_c('modal',{attrs:{"title":"Create System","max-width":"380"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({attrs:{"width":"180"}},'btn',attrs,false),on),[_vm._v("Create System")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('add-system',{on:{"done":close}})]}}])})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAddComponentBtn),expression:"showAddComponentBtn"}],attrs:{"cols":"auto"}},[_c('modal',{attrs:{"title":"Create Component","max-width":"480"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({attrs:{"width":"220"}},'btn',attrs,false),on),[_vm._v("Create Component")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('add-component',{on:{"done":close}})]}}])})],1)],1),_c('v-row',[_c('manage-database')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }