import Btn from '@/components/Btn/Btn.vue';
import SYSTEMS_QUERY from '@/graphql/queries/systems.gql';
import Database from '@/components/ManageDatabase/Database/Database.vue';

export default {
  name: 'ManageDatabase',
  components: { Btn, Database },
  data: () => ({
    SYSTEMS_QUERY,
  }),
};
