var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"add-component-form",attrs:{"mutation":_vm.ADD_COMPONENT_MUTATION,"variables":{
    input: {
      systemId: _vm.component.system,
      name: _vm.component.name,
      costPerUnit: _vm.component.costPerUnit,
      unitId: _vm.component.unit,
    },
  },"update":_vm.updateSystems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('component-form',_vm._b({on:{"submit":function($event){return mutate()}}},'component-form',{
        btnLabel: 'Create',
        loading: loading,
        component: _vm.component,
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }